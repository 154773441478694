<template>
  <div
    class="bg-primary text-center"
    style="height: 100vh;"
  >
    <b-spinner
      style="width: 3rem; height: 3rem; top: 35%; left: 47%; position: absolute;"
      variant="light"
      label="Text Centered"
    />
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
  data() {
    return { }
  },
  mounted() {
    this.validateToken()
  },
  methods: {
    validateToken() {
      this.$store.dispatch('auth/validate', this.$route.params.hash)
        .then(() => {
          this.$router.push('/')
        })
        .catch(() => { this.$router.push('/unauthenticated') })
    },
  },
}
</script>
